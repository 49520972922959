<template>
  <div>
    <main class="o-row">
      <div class="c-row" v-for="ed in getEventDisciplines()" :key="ed.id">
        <div class="o-layout u-margin-bottom-medium">
          <h4 class="c-title c-title--session o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-1-of-5-at-small">
            {{$t('sessions.active')}}
          </h4>
          <div class="o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small">
            <h3 class="c-title c-title--event">{{ed.name}}</h3>
          </div>
        </div>

        <div class="o-layout u-margin-bottom-medium"  v-for="session in getSessions(ed.id)" :key="session.id">
          <h4 class="c-title c-title--session o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-1-of-5-at-small">
            {{session.name}}
          </h4>
          <div class="c-button--group o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-4-of-6-at-small">
            <router-link class="c-button c-button--large c-button--primary" tag="button"
              :to="{ name: 'cis.session.panels', params: { eventId: $route.params.eventId, sessionId: session.id}}">
              {{ $t('panels')}}
            </router-link>
            <router-link class="c-button c-button--large c-button--primary" tag="button"
                         :to="{ name: 'cis.session.results', params: { eventId: $route.params.eventId, sessionId: session.id}}">
              {{ $t('results')}}
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  import filter from 'lodash/filter';
  import sortBy from 'lodash/sortBy';

  export default {
    name: "cis-sessions",
    computed: {
      event: function () {
        return this.$store.state.events.current
      },
      eventDisciplines: function () {
        return this.$store.state.eventDisciplines.items;
      },
      sessions: function () {
        const tmp = filter(this.$store.state.sessions.items, function (session) {
          return session.status === 'active'
        });
        return sortBy(tmp, 'index');
      },
    },
    methods: {
      getEventDisciplines: function() {
        return filter(this.eventDisciplines, ed => this.hasSessions(ed.id))
      },
      hasSessions: function(edId) {
        return this.getSessions(edId).length > 0;
      },
      getSessions: function (edId) {
        return filter(this.sessions, function (item) {
          return item.eventDisciplineId === edId;
        });
      },
    }
  }
</script>

<style scoped>
</style>
