<template>
  <div>SESSION RESULTS</div>
</template>

<script>
export default {
  name: 'cisSessionResults'
}
</script>

<style scoped>

</style>
