<template>
  <div class="c-container c-container--full-vh c-wrapper--min-100vh">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "cis-session",
  }
</script>
