<template>
  <ranking v-if="ranking" :key="ranking.id" :ranking="ranking" :filter="$options.filter" />
</template>

<script>
import find from 'lodash/find';

export default {
  name: "sessionRanking",
  components: {
    ranking: () => import('client/components/results/ranking.vue'),
  },
  filter: {
    subDivision: 0,
    set: 0,
    awards: false,
  },
  computed: {
    ranking: function() {
      return find(this.$store.state.rankings.items, item => {
        return item.id === this.$route.params.rankingId;
      });
    },
  },
}
</script>

<style scoped>

</style>
